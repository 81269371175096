
import Vue from 'vue';

import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import TextInput from 'Control/TextInput.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import Button from 'Control/Button.vue';
import CountryInput from 'Control/CountryInput.vue';
import Checkbox from 'Control/Checkbox.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import WalletsApi from 'Apis/Wallets';
import CreateBankRequisitesRequestData from 'Entities/walletExecutor/CreateBankRequisitesRequestData';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import ApiError from 'Entities/ApiError';

type Data = {
    assetsList: string[];
    assetBlockchains: Map<string, string[]>;
    previousRoute: string;
    step: number;
    formData: {
        alias: string;
        assetIndex: number;
        blockchainIndex: number;
        beneficiaryAccountNumber: string;
        beneficiarySwift: string;
        beneficiaryNameIndex: number;
        beneficiaryCountry: string;
        beneficiaryCountryCode: string;
        bankCountry: string;
        bankCountryCode: string;
        beneficiaryAddress: string;
        bankAddress: string;
        isUserAgree: boolean;
    };
    errors: {
        alias: boolean;
        beneficiaryAccountNumber: boolean;
        beneficiarySwift: boolean;
        beneficiaryAddress: boolean;
        bankAddress: boolean;
        isUserAgree: boolean;
    };
};

type Methods = {
    goBack: () => void;
    setAlias: (data: string) => void;
    setAccountNumber: (data: string) => void;
    setSwift: (data: string) => void;
    setAsset: (data: number) => void;
    setBlockchain: (data: number) => void;
    setBeneficiaryName: (data: number) => void;
    setBeneficiaryAddress: (data: string) => void;
    setBankAddress: (data: string) => void;
    handleBeneficiaryCountry: (data: { name: string; code: string; }) => void;
    handleBankCountry: (data: { name: string; code: string; }) => void;
    nextStep: () => void;
    cancel: () => void;
    toggleIsUserAgree: () => void;
    validateSecondStep: () => boolean;
    save: () => void;
};

type Computed = {
    currentUser: InternalUserResponse | null;
    isKycVerified: boolean;
    validBeneficiaryNames: string[];
    availableBlockchains: string[];
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Checkbox,
        CountryInput,
        Button,
        AssetsBlockchainsDropdown,
        TextInput,
    },
    data() {
        return {
            assetsList: [],
            assetBlockchains: new Map(),
            previousRoute: '',
            step: 1,
            formData: {
                alias: '',
                assetIndex: 0,
                blockchainIndex: 0,
                beneficiaryNameIndex: 0,
                beneficiaryAccountNumber: '',
                beneficiarySwift: '',
                beneficiaryCountry: '',
                beneficiaryCountryCode: '',
                bankCountry: '',
                bankCountryCode: '',
                beneficiaryAddress: '',
                bankAddress: '',
                isUserAgree: false,
            },
            errors: {
                alias: false,
                beneficiaryAccountNumber: false,
                beneficiarySwift: false,
                beneficiaryAddress: false,
                bankAddress: false,
                isUserAgree: false,
            },
        };
    },
    computed: {
        availableBlockchains() {
            return this.assetBlockchains
                .get(this.assetsList[this.formData.assetIndex])
                ?? [];
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        validBeneficiaryNames() {
            return this.currentUser?.possibleNames ?? [];
        },
    },
    methods: {
        async goBack() {
            if (this.previousRoute) {
                await this.$router.push(this.previousRoute).catch(() => { /* navigation error */ });
                return;
            }

            this.$router.go(-1);
        },
        nextStep() {
            switch (this.step) {
                case 1: {
                    this.formData.alias = this.formData.alias.trim();
                    if (this.formData.alias.length > 0) {
                        this.step += 1;
                    } else {
                        this.errors.alias = true;
                    }
                    break;
                }
                case 2: {
                    if (this.validateSecondStep()) {
                        this.save();
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        },
        validateSecondStep() {
            this.formData.beneficiaryAccountNumber = this.formData.beneficiaryAccountNumber.trim();
            this.formData.beneficiarySwift = this.formData.beneficiarySwift.trim();
            this.formData.beneficiaryAddress = this.formData.beneficiaryAddress.trim();
            this.formData.bankAddress = this.formData.bankAddress.trim();

            let isValid = true;
            if (this.formData.beneficiaryAccountNumber.length === 0) {
                this.errors.beneficiaryAccountNumber = true;
                isValid = false;
            }
            if (this.formData.beneficiarySwift.length === 0) {
                this.errors.beneficiarySwift = true;
                isValid = false;
            }
            if (this.formData.beneficiaryAddress.length === 0) {
                this.errors.beneficiaryAddress = true;
                isValid = false;
            }
            if (this.formData.bankAddress.length === 0) {
                this.errors.bankAddress = true;
                isValid = false;
            }
            if (!this.formData.isUserAgree) {
                this.errors.isUserAgree = true;
                isValid = false;
            }

            return isValid;
        },
        async save() {
            try {
                const { emailCode, totpCode } = await this.$store.dispatch(
                    'Auth/getMFAToken',
                    {
                        type: MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP,
                        action: 'SaveBankRequisites',
                        emailTemplateData: {
                            recipientName: this.validBeneficiaryNames[this.formData.beneficiaryNameIndex],
                            bankAccountNumber: this.formData.beneficiaryAccountNumber,
                        },
                    },
                );

                this.$store.commit(SET_LOADING_ON(undefined));
                await WalletsApi.saveUserBankRequisites(new CreateBankRequisitesRequestData({
                    alias: this.formData.alias,
                    asset: this.assetsList[this.formData.assetIndex],
                    paymentNetwork: this.availableBlockchains[this.formData.blockchainIndex].toLowerCase() as 'sepa' | 'swift',
                    beneficiaryAccountIdentifier: this.formData.beneficiaryAccountNumber,
                    beneficiaryAddress: this.formData.beneficiaryAddress,
                    beneficiaryBankAddress: this.formData.bankAddress,
                    beneficiaryBankCountry: this.formData.bankCountry,
                    beneficiaryBankCountryCode: this.formData.bankCountryCode,
                    beneficiaryBankIdentifier: this.formData.beneficiarySwift,
                    beneficiaryCountry: this.formData.beneficiaryCountry,
                    beneficiaryCountryCode: this.formData.beneficiaryCountryCode,
                    beneficiaryName: this.validBeneficiaryNames[this.formData.beneficiaryNameIndex],
                    emailTotp: emailCode,
                    totp: totpCode,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Account has been successfully saved');
                await this.goBack();
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error?.data?.message ?? 'Something went wrong');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async cancel() {
            if (this.step === 1) {
                await this.goBack();
                return;
            }
            this.step -= 1;
        },
        setAlias(value) {
            this.formData.alias = value;
            this.errors.alias = false;
        },
        setAccountNumber(value) {
            this.formData.beneficiaryAccountNumber = value;
            this.errors.beneficiaryAccountNumber = false;
        },
        setSwift(value) {
            this.formData.beneficiarySwift = value;
            this.errors.beneficiarySwift = false;
        },
        setBeneficiaryAddress(value) {
            this.formData.beneficiaryAddress = value;
            this.errors.beneficiaryAddress = false;
        },
        setBankAddress(value) {
            this.formData.bankAddress = value;
            this.errors.bankAddress = false;
        },
        setAsset(value) {
            this.formData.assetIndex = value;
            this.formData.blockchainIndex = 0;
        },
        setBlockchain(value) {
            this.formData.blockchainIndex = value;
        },
        setBeneficiaryName(value) {
            this.formData.beneficiaryNameIndex = value;
        },
        handleBeneficiaryCountry(data) {
            const { name, code } = data;
            this.formData.beneficiaryCountry = name;
            this.formData.beneficiaryCountryCode = code;
        },
        handleBankCountry(data) {
            const { name, code } = data;
            this.formData.bankCountry = name;
            this.formData.bankCountryCode = code;
        },
        toggleIsUserAgree() {
            this.formData.isUserAgree = !this.formData.isUserAgree;
            this.errors.isUserAgree = false;
        },
    },
    mounted() {
        const {
            previousRoute,
            assets,
        } = this.$route.query;
        if (assets) {
            this.assetsList = JSON.parse(assets as string).map(({ symbol }) => symbol);

            this.assetBlockchains = JSON.parse(assets as string).reduce((accum, current) => {
                if (
                    current.symbol
                    && current.transferDetails
                    && current.transferDetails.length
                ) {
                    const blockchains = new Set(current.transferDetails.reduce((blockchainsAccum, { blockchainName }) => {
                        if (blockchainName) {
                            blockchainsAccum.push(blockchainName);
                        }
                        return blockchainsAccum;
                    }, []));
                    accum.set(current.symbol, Array.from(blockchains));
                }
                return accum;
            }, new Map());
        }
        this.previousRoute = previousRoute as string;
        if (this.$v) {
            this.$v.$reset();
        }
    },
});
